import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

export const takeScreenShot = async (
  node: any,
  type = 'window',
  isTokenization = false
) => {
  const canvasPromise = html2canvas(node, {
    useCORS: true,
  });
  canvasPromise.then((canvas) => {
    const dataURL: string = canvas.toDataURL('image/png');
    const height = isTokenization ? 110 : 140;
    const pdf = new jspdf('portrait', 'mm', [90, height]);

    if (type === 'window') {
      const width = pdf.internal.pageSize.width;
      const height = pdf.internal.pageSize.height;

      pdf.addImage(dataURL, 'PNG', 0, 0, width, height);
      pdf.save('receipt.pdf');
      return;
    }
    const file = base64ToFile(dataURL);

    navigator.share({
      url: String(window.location),
      files: [file],
      text: 'Receipt',
      title: 'Статус',
    });
  });
};

const base64ToFile = (url: string) => {
  const arr: string[] = url.split(',');
  const mime: string = arr[0].match(/:(.*?);/)![1];
  const data: string = arr[1];

  const dataStr: string = atob(data);
  let n: number = dataStr.length;
  const dataArr = new Uint8Array(n);

  while (n--) {
    dataArr[n] = dataStr.charCodeAt(n);
  }

  const file: File = new File([dataArr], 'Receipt.png', { type: mime });

  return file;
};
