export enum EndpointsPhonesQueryKeys {
  Check = '/paybal/sdk/check',
  Pay = '/paybal/sdk/pay',
  TokenizeCheck = '/tokenization/submit',
  TokenizationCommit = '/tokenization/commit',
  Logo = '/operator/logo',
}

export enum PhonesQueryKeys {
  Logo = 'mobile-logo',
}

export const tokenizationOperator = 'operator';
