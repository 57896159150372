import { useMutation, useQuery } from '@tanstack/react-query';
import { instance } from '@configs';
import { toast } from 'react-toastify';

import { IResponse } from '@types';
import { redirect } from '@utils';

import { IStatus } from './types';
import { EndpointsStatusesQueryKeys, StatusQueryKeys } from './constants';

export const useGetStatusQuery = (id: string) => {
  return useQuery({
    queryFn: async (): Promise<IStatus> => {
      const { data } = await instance.get<IStatus>(
        `${EndpointsStatusesQueryKeys.Status}/${id}`
      );
      return data;
    },
    queryKey: [StatusQueryKeys.Status, id],
  });
};

export const useRepayQuery = () => {
  return useMutation({
    mutationFn: async (): Promise<IResponse<null>> => {
      const { data } = await instance.post(EndpointsStatusesQueryKeys.Repay);
      return data;
    },
    onSuccess: (data) => {
      if (data.code === 200) {
        toast.success(data.message);
        redirect('/');
      }
    },
  });
};
