import { useFormik } from 'formik';

import { SendOtp } from '@entities/send-otp';
import { redirect } from '@utils';
import { Button, Input, Offerta } from '@ui';
import { IResponse } from '@types';

import { validationSchemaFormMobi } from './schema';
import { IOtpFormMobi, ISmsProps } from './types';

import { wallet } from '../../constants';

export const Sms = ({
  phone,
  handlePrevStep,
  sendOtp,
  isLoading,
  isTokenization,
}: ISmsProps) => {
  const { values, isValid, setFieldValue, errors, handleSubmit } =
    useFormik<IOtpFormMobi>({
      initialValues: { otp: '' },
      validationSchema: validationSchemaFormMobi,
      onSubmit: ({ otp }) => {
        sendOtp(
          { otp: String(otp), phone: phone, gate: wallet },
          {
            onSuccess: (data: IResponse<string>) => {
              if (data.code === 200) {
                redirect(data?.payload);
              }
            },
          }
        );
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <p>SMS с кодом подтверждения отправлено на номер {phone}</p>
      <p
        onClick={handlePrevStep}
        className="mb-8 mt-2 inline-block cursor-pointer text-primary hover:underline sm:mb-4"
      >
        Изменить номер
      </p>
      <Input
        mask="99999"
        maskChar=" "
        label="Введите код"
        placeholder="60881"
        errorText={errors.otp}
        initialValue={values.otp}
        isError={Boolean(errors.otp)}
        onChange={(value) => setFieldValue('otp', value)}
      />
      <div className="mt-4 flex flex-col items-center justify-center gap-2">
        <SendOtp />
      </div>
      <Button
        type="submit"
        isLoading={isLoading}
        disabled={!isValid}
        className="mb-4 mt-6 w-full justify-center"
      >
        {isTokenization ? 'Привязать' : 'Оплатить'}
      </Button>
      <Offerta isTokenization={isTokenization} />
    </form>
  );
};
