'use client';

import { ChangeEvent, useId } from 'react';

import clsx from 'clsx';
import InputMask from 'react-input-mask';

import { IInputProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ru = require('convert-layout/ru');

export const Input = ({
  initialValue,
  className,
  isError,
  errorText,
  endIcon,
  startIcon,
  placeholder,
  label = '',
  onClick,
  step = '',
  name = '',
  autocomplete = 'off',
  classNameEndIcon,
  classNameStartIcon,
  type = 'text',
  disabled = false,
  onChange = () => null,
  translateEn = false,
  mask = '',
  maskChar,
  maxLength,
  absoluteErrorText = false,
}: Partial<IInputProps>) => {
  const id: string = useId();

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>): void => {
    const value: string = event.target.value;
    const parsedValue: string = translateEn ? ru.toEn(value) : value;

    onChange(parsedValue, event);
  };

  return (
    <div className={clsx(label && 'relative flex w-full flex-col gap-2')}>
      {label && (
        <label
          className={clsx('pl-1 text-sm', [
            isError ? 'text-red' : 'text-grey-500',
          ])}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <i className={clsx('absolute left-2.5 top-2', classNameStartIcon)}>
          {startIcon}
        </i>
        <InputMask
          mask={mask}
          maskChar={maskChar}
          value={initialValue}
          disabled={disabled}
          onChange={handleChangeValue}
        >
          {(props: any) => {
            return (
              <input
                {...props}
                id={id}
                name={name}
                step={step}
                type={type}
                onClick={onClick}
                value={initialValue}
                placeholder={placeholder}
                onChange={handleChangeValue}
                maxLength={maxLength ?? undefined}
                autoComplete={autocomplete}
                className={clsx(
                  'w-full rounded-[8px] border border-black/50 border-grey-100 bg-transparent px-5 py-4 text-sm outline-none placeholder:text-grey-500 sm:p-3.5',
                  [startIcon && 'pl-8'],
                  [endIcon && 'pr-8'],
                  [isError && 'border-red focus:border-red'],
                  [isError ? 'focus:border-red' : 'focus:border-primary'],
                  [isError ? 'hover:border-red' : 'hover:border-primary'],
                  className
                )}
              />
            );
          }}
        </InputMask>
        <i className={clsx('absolute right-2.5 top-2', classNameEndIcon)}>
          {endIcon}
        </i>
      </div>

      {errorText && (
        <p
          className={clsx('pl-4 text-xs font-medium text-red', [
            absoluteErrorText && 'absolute -bottom-6 sm:static',
          ])}
        >
          {errorText}
        </p>
      )}
    </div>
  );
};
