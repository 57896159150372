import { useFormik } from 'formik';
import { getLogoMobileQuery } from '@features/form-phones/api';
import { parseNumber } from '@features/form-phones/components/registration/utils';

import { Button, If, InfoPayment, Input } from '@ui';
import { checkFeeInfo, numberFormat } from '@utils';
import { ITransactionType } from '@entities/checkout';
import { BASE_URL_LOGOS } from '@constants';

import { validationSchemaFormPhones } from './schema';
import { IRegFormPhone, IRegistrationForm } from './types';

export const RegistrationForm = ({
  handleSetValuePhone,
  handleNextStep,
  checkMobi,
  isLoading,
  checkout,
  typeTransaction,
  isTokenization = false,
}: IRegistrationForm) => {
  const { values, setFieldValue, handleSubmit, isValid, errors } =
    useFormik<IRegFormPhone>({
      initialValues: { phone: '+992 ' },
      validationSchema: validationSchemaFormPhones,
      onSubmit: (values) => {
        checkMobi(values, {
          onSuccess: (data) => {
            if (data.code === 200) {
              handleNextStep();
            }
          },
        });
      },
    });

  const { data: logo } = getLogoMobileQuery(parseNumber(values.phone));

  const handleChange = (value: string): void => {
    handleSetValuePhone(value);
    setFieldValue('phone', value);
  };

  const infoPay: ITransactionType | undefined =
    checkout?.transactionTypes?.find(
      (transactionType) => transactionType.groupName === typeTransaction
    );

  const min: string = checkFeeInfo('min', String(infoPay?.feeInfo.min), ',');
  const max: string = checkFeeInfo('max', String(infoPay?.feeInfo.max));
  const infoStatic: string = checkFeeInfo(
    ' +',
    String(infoPay?.feeInfo.static),
    ','
  );

  return (
    <>
      <form onSubmit={handleSubmit} className="flex w-full flex-col gap-5">
        <Input
          maskChar=" "
          placeholder="+992 "
          mask="+999 999 99 99 99"
          label="Номер телефона"
          classNameEndIcon="!top-3 sm:!top-2.5"
          initialValue={values.phone}
          errorText={errors.phone || logo?.detailedInfo}
          isError={Boolean(errors.phone || logo?.detailedInfo)}
          onChange={(value) => handleChange(value)}
          endIcon={
            <If
              condition={Boolean(logo?.brand && logo?.brand !== 'default.png')}
            >
              <img
                alt="Logo"
                width={30}
                height={30}
                src={`${BASE_URL_LOGOS}/logos/${logo?.brand}`}
              />
            </If>
          }
        />

        <If condition={!isTokenization}>
          <InfoPayment
            className="mb-4"
            info={[
              {
                label: 'Комиссия',
                value: `${numberFormat(Number(infoPay?.totalFee))} c. ( ${infoPay?.feeInfo?.percent}%${infoStatic} ${min} ${max})`,
              },
              {
                label: 'Сумма к зачислению',
                value: `${numberFormat(Number(checkout?.amount))} с.`,
              },
              {
                label: 'К оплате',
                value: `${numberFormat(Number(infoPay?.totalAmount))} с.`,
              },
            ]}
          />
        </If>
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={isLoading}
          className="w-full justify-center"
        >
          Далее
        </Button>
      </form>
    </>
  );
};
