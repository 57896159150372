import { Link, useSearchParams } from 'react-router-dom';

import { ContainerWrapper, StatusesTokenizePdf } from '@utils';
import { Button, If, Loader } from '@ui';
import { useGetStatusTokenizeQuery } from '@entities/statuses-tokenize';
import { HeaderStatus } from '@entities/statuses';
import { Statuses as ConstantsStatuses } from '@constants';

export const StatusesTokenize = () => {
  const [searchParams] = useSearchParams();
  const statusId: string = searchParams.get('id') || '';

  const { data: status, isPending } = useGetStatusTokenizeQuery(statusId);

  return (
    <ContainerWrapper>
      <If anotherChildren={<Loader />} condition={!isPending}>
        <div className="mx-auto my-[120px] h-auto w-[800px] rounded-3xl bg-white p-24 sm:my-auto sm:w-full sm:rounded-none sm:px-5 sm:pb-[90px] sm:pt-10">
          <HeaderStatus
            title={status?.result || 'Упс..'}
            logo={String(status?.status)}
            reason={status?.reasonStatus || ''}
          />
          <div className="mx-auto flex w-[500px] flex-col gap-3 sm:w-full">
            <div className="ite ms-center flex justify-between border-b border-grey-30 p-5 text-base sm:p-3">
              <p className="text-grey-500">Номер транзакции</p>
              <p className="font-semibold">{status?.id}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-5 text-base sm:p-3">
              <p className="text-grey-500">ID заказа</p>
              <p>{status?.requestId}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-5 text-base sm:p-3">
              <p className="text-grey-500">Результат</p>
              <p>{status?.result}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-5 text-base sm:p-3">
              <p className="text-grey-500">Получатель</p>
              <p>{status?.brand}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-5 text-base sm:p-3">
              <p className="text-grey-500">Метод привязки</p>
              <p>{status?.paymentMethod}</p>
            </div>
            <div className="flex items-center justify-between p-5 text-base sm:p-3">
              <p className="text-grey-500">Дата и время</p>
              <p>{status?.dateTime}</p>
            </div>
            <If
              condition={
                Boolean(status?.status) &&
                Boolean(status?.returnUrl) &&
                ConstantsStatuses.Proggres !== status?.status
              }
            >
              <Link to={status?.returnUrl || '/'}>
                <Button
                  variant="outlined"
                  className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:w-full"
                >
                  Вернуться на сайт
                </Button>
              </Link>
            </If>
            <If
              condition={
                Boolean(status?.status) &&
                ConstantsStatuses.Proggres !== status?.status
              }
            >
              <StatusesTokenizePdf status={status} />
            </If>
            <If condition={ConstantsStatuses.Proggres === status?.status}>
              <Button
                variant="outlined"
                onClick={() => window.location.reload()}
                className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:w-full"
              >
                Обновить страницу
              </Button>
            </If>
            <If condition={ConstantsStatuses.Unsuccessful === status?.status}>
              <div>
                <Button
                  variant="contained"
                  onClick={() => window.location.reload()}
                  className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:w-full"
                >
                  Повторить попытку
                </Button>
              </div>
            </If>
          </div>
        </div>
      </If>
    </ContainerWrapper>
  );
};
