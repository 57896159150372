import { useRef } from 'react';

import { AlifPayIcon, DowloandIcon } from '@icons';

import { Button } from '@ui';
import { takeScreenShot } from '@utils';
import { IStatus } from '@entities/statuses';

export const StatusesPdf = ({ status }: { status: IStatus | undefined }) => {
  const ref = useRef(null);
  const downloadScreenshot = () => takeScreenShot(ref.current, 'window');
  const downloadScreenshotMobile = () => takeScreenShot(ref.current, 'mobile');

  return (
    <>
      <div className="fixed -left-[999999px] top-0 w-[500px] opacity-0">
        <div ref={ref} className="bg-white p-10">
          <div className="my-2 flex flex-col items-center justify-center">
            <AlifPayIcon />
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">ID заказа</p>
              <p className="font-semibold">{status?.orderId}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Получатель</p>
              <p className="pl-5">{status?.brand}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Дата и время</p>
              <p>
                {status?.dt}, {status?.time}
              </p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Примечание</p>
              <p>{status?.info}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Метод оплаты</p>
              <p>{status?.paymentMethod}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Номер транзакции</p>
              <p>{status?.txn}</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Общая сумма</p>
              <p>{status?.amount} с.</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Комиссия</p>
              <p>{status?.comission} с.</p>
            </div>
            <div className="flex items-center justify-between border-b border-grey-30 p-2 text-base">
              <p className="text-grey-500">Итог</p>
              <p className="font-semibold">{status?.total} с.</p>
            </div>
            <div className="flex items-center justify-between p-2 text-base">
              <p className="text-grey-500">Статус</p>
              <p>{status?.result}</p>
            </div>
            <img src="/assets/seal.png" className="mx-auto mt-8 w-[260px]" />
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        onClick={downloadScreenshot}
        endIcon={<DowloandIcon className="text-white" />}
        className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:hidden sm:w-full"
      >
        Сохранить
      </Button>
      <Button
        variant="contained"
        onClick={downloadScreenshotMobile}
        endIcon={
          <img
            alt="icon"
            width={22}
            height={22}
            src="/assets/share.png"
            className="text-white"
          />
        }
        className="mx-auto mt-6 hidden w-full justify-center sm:mt-3 sm:flex sm:w-full"
      >
        Поделиться
      </Button>
    </>
  );
};
