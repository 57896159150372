import { useRef } from 'react';

import { Link, useSearchParams } from 'react-router-dom';

import { Button, If, Loader } from '@ui';
import { ContainerWrapper, StatusesPdf } from '@utils';
import {
  HeaderStatus,
  useGetStatusQuery,
  useRepayQuery,
} from '@entities/statuses';
import { Statuses as ConstantsStatuses } from '@constants';

export const Statuses = () => {
  const [searchParams] = useSearchParams();
  const statusId: string = searchParams.get('id') || '';
  const ref = useRef(null);

  const { data: status, isPending } = useGetStatusQuery(statusId);
  const { mutate: repay } = useRepayQuery();

  return (
    <ContainerWrapper>
      <div ref={ref}>
        <If anotherChildren={<Loader />} condition={!isPending}>
          <div className="mx-auto my-[120px] h-auto w-[800px] rounded-3xl bg-white p-24 sm:my-auto sm:w-full sm:rounded-none sm:px-5 sm:pb-[90px] sm:pt-10">
            <HeaderStatus
              title={status?.result || 'Упс..'}
              logo={String(status?.status)}
              reason={status?.reasonStatus || ''}
            />
            <div className="mx-auto flex w-[500px] flex-col gap-3 sm:w-full">
              <div className="flex items-center justify-between border-b border-grey-30 p-5 text-base sm:p-3">
                <p className="text-grey-500">ID заказа</p>
                <p className="font-semibold">{status?.orderId}</p>
              </div>
              <div className="flex items-center justify-between border-b border-grey-30 p-4 text-base sm:p-3">
                <p className="text-grey-500">Получатель</p>
                <p className="pl-5">{status?.brand}</p>
              </div>
              <div className="flex items-center justify-between border-b border-grey-30 p-4 text-base sm:p-3">
                <p className="text-grey-500">Дата и время</p>
                <p>
                  {status?.dt}, {status?.time}
                </p>
              </div>
              <div className="flex items-center justify-between border-b border-grey-30 p-4 text-base sm:p-3">
                <p className="text-grey-500">Примечание</p>
                <p>{status?.info}</p>
              </div>
              <div className="flex items-center justify-between border-b border-grey-30 p-4 text-base sm:p-3">
                <p className="text-grey-500">Метод оплаты</p>
                <p>{status?.paymentMethod}</p>
              </div>
              <div className="flex items-center justify-between border-b border-grey-30 p-4 text-base sm:p-3">
                <p className="text-grey-500">Номер транзакции</p>
                <p>{status?.txn}</p>
              </div>
              <div className="flex items-center justify-between border-b border-grey-30 p-4 text-base sm:p-3">
                <p className="text-grey-500">Общая сумма</p>
                <p>{status?.amount} с.</p>
              </div>
              <div className="flex items-center justify-between border-b border-grey-30 p-4 text-base sm:p-3">
                <p className="text-grey-500">Комиссия</p>
                <p>{status?.comission} с.</p>
              </div>
              <div className="flex items-center justify-between p-4 text-base sm:p-3">
                <p className="text-grey-500">Итог</p>
                <p className="font-semibold">{status?.total} с.</p>
              </div>
              <If
                condition={
                  Boolean(status?.status) &&
                  Boolean(status?.returnUrl) &&
                  ConstantsStatuses.Proggres !== status?.status
                }
              >
                <Link to={status?.returnUrl || '/'}>
                  <Button
                    variant="outlined"
                    className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:w-full"
                  >
                    Вернуться на сайт
                  </Button>
                </Link>
              </If>
              <If
                condition={
                  Boolean(status?.status) &&
                  ConstantsStatuses.Proggres !== status?.status
                }
              >
                <StatusesPdf status={status} />
              </If>
              <If condition={Boolean(status?.canRetry)}>
                <Button
                  className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:w-full"
                  onClick={() => repay()}
                >
                  Повторить попытку
                </Button>
              </If>
              <If condition={ConstantsStatuses.Proggres === status?.status}>
                <Button
                  variant="outlined"
                  onClick={() => window.location.reload()}
                  className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:w-full"
                >
                  Обновить страницу
                </Button>
              </If>
              <If condition={ConstantsStatuses.Unsuccessful === status?.status}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => window.location.reload()}
                    className="mx-auto mt-6 w-[50%] justify-center sm:mt-3 sm:w-full"
                  >
                    Повторить попытку
                  </Button>
                </div>
              </If>
            </div>
          </div>
        </If>
      </div>
    </ContainerWrapper>
  );
};
