import { Link } from 'react-router-dom';
import { useCheckout } from '@hooks';

import { If } from '@ui';
import { BASE_URL_LOGOS } from '@constants';

export const Navbar = () => {
  const { checkout } = useCheckout();

  return (
    <If condition={Boolean(checkout?.logo)}>
      <nav className="bg-white">
        <div className="container justify-center p-3">
          <Link to="/">
            <If condition={Boolean(checkout?.logo)}>
              <img
                alt="Logo"
                className="w-[130px]"
                src={`${BASE_URL_LOGOS}/logos/${checkout?.logo}`}
              />
            </If>
          </Link>
        </div>
      </nav>
    </If>
  );
};
