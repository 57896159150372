import { useEffect, useState } from 'react';

import ProgressBar from '@ramonak/react-progress-bar';
import clsx from 'clsx';

import { If } from '@ui';

import { useSendOtpQuery } from '../../api';

const waitMinute = 180; // 3 minute

export const SendOtp = () => {
  const [time, setTime] = useState(waitMinute);
  const [isSendOtp, setIsSendOtp] = useState<boolean>(false);

  const { mutate, isPending } = useSendOtpQuery();

  const handleSendOtp = (): void => {
    mutate(null, {
      onSuccess: () => {
        setTime(waitMinute);
        setIsSendOtp(false);
      },
    });
  };

  const minute: string = `${Math.floor(time / 60)}`.padStart(2, '0');
  const seconds: string = `${time % 60}`.padStart(2, '0');

  const handleTime = () => {
    const timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    handleTime();
  }, [isSendOtp]);

  useEffect(() => {
    if (time === 0) {
      setIsSendOtp(true);
    }
  }, [time]);

  return (
    <>
      <p className="text-base text-grey-500">
        {isSendOtp
          ? 'Можно повторно отправить SMS'
          : `Отправить SMS повторно можно через ${minute} мин ${seconds} сек.`}
      </p>
      <ProgressBar
        height="7px"
        isLabelVisible={false}
        className="my-2 w-full"
        bgColor="#3899E2"
        maxCompleted={waitMinute}
        completed={isSendOtp ? 0 : time}
      />

      <p
        onClick={() => (isSendOtp && !isPending ? handleSendOtp() : null)}
        className={clsx([
          isSendOtp && !isPending
            ? 'cursor-pointer text-primary hover:underline'
            : 'cursor-not-allowed text-grey-500',
        ])}
      >
        Получить новый код
      </p>
    </>
  );
};
