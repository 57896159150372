import { useMutation } from '@tanstack/react-query';
import { instance } from '@configs';
import { toast } from 'react-toastify';

import { IResponse } from '@types';
import { getToken } from '@utils';

import { EndpointsSendOtpTokenizeQueryKeys } from './constants';

export const useSendOtpQuery = () => {
  return useMutation({
    mutationFn: async (_: null): Promise<IResponse<string>> => {
      const { data } = await instance.post<IResponse<string>>(
        EndpointsSendOtpTokenizeQueryKeys.SendOtp,
        {},
        {
          headers: {
            SessionToken: getToken(),
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};
