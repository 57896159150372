import { If, Loader, Tabs } from '@ui';
import { ContainerWrapper, parserTransactionsTypes } from '@utils';
import { useGetTokenizeCheckoutQuery } from '@entities/checkout';

export const Tokenization = () => {
  const { data: checkout, isLoading } = useGetTokenizeCheckoutQuery();
  const [headers, childes] = parserTransactionsTypes(checkout, true);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ContainerWrapper>
      <div className="my-[120px] sm:my-[44px]">
        <div className="mx-auto flex w-[65%] flex-col gap-10 sm:w-full sm:gap-2 sm:overflow-hidden">
          <If condition={headers.length !== 1}>
            <div className="mb-2 mt-10 sm:mt-0 sm:px-5">
              <p className="font-semibold">Выберите способ привязки</p>
            </div>
          </If>
          <If condition={Boolean(headers.length && childes.length)}>
            <Tabs headers={headers} childes={childes} onChange={() => null} />
          </If>
        </div>
      </div>
    </ContainerWrapper>
  );
};
